<template>
    <div class="consume-wrapper materialConsumeDaily" v-loading="loading">
        <div class="consume-box">
            <header>
                <ul>
                    <li>
                        <el-select v-model="formInline.station_id" placeholder="选择场站" @change="stationChange">
                            <el-option
                                v-for="(item,index) in stationData"
                                :key="index"
                                :label="item.name"
                                :value="item.station_id"
                            ></el-option>
                        </el-select>
                    </li>
                    <li>
                        <el-select v-model="formInline.line_code" placeholder="选择生产机组" @change="lineChange">
                            <el-option value="-" label="全部"></el-option>
                            <el-option
                                v-for="(item,index) in lineData"
                                :key="index"
                                :label="item.line_name"
                                :value="item.line_code"
                            ></el-option>
                        </el-select>
                    </li>
                    <li>
                        <el-date-picker
                            v-model="timeArray"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-value="new Date()"
                            @change="timeChange"
                        >
                        </el-date-picker>
                    </li>
                    <li>
                        <button class="export_btn" @click="exportExcel">
                            <i class="iconfont icondaochu"></i>
                            <span>导出excel</span>
                        </button>
                    </li>
                </ul>
            </header>
            <section>
                <div class="table-wrapper table-bg" id="tableWrapper">
                    <el-table
                        id="out-table"
                        :data="tableData"
                        style="width: 100%;"
                        :header-row-style="{background: '#edf0f5',color: '#022782'}"
                        stripe
                        :summary-method="getSummaries"
                        show-summary
                        height="98%"
                        ref="table"
                    >
                        <el-table-column label="料仓编号" prop="stock_code"></el-table-column>
                        <el-table-column label="料仓号" prop="plcw"></el-table-column>
                        <el-table-column label="原材料名称" prop="material_child_name"></el-table-column>
                        <el-table-column label="原材料规格" prop="material_name"></el-table-column>
                        <el-table-column label="实际用量（吨）" prop="actual_value"></el-table-column>
                        <el-table-column label="配比用量（吨）" prop="theory_volume"></el-table-column>
                    </el-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
import currencyFun from '@/assets/js/currencyFun';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
export default {
    // 生产管理-报表统计-原材料消耗日报表
    name: 'material-consume-daily',
    data() {
        return {
            stationData: [], // 场站list
            lineData: [], // 生产机组
            timeArray: [], // 时间数组
            formInline: {
                station_id: '',
                line_code: '-',
            },
            tableData: [],
            loading: false,
        };
    },
    async created() {
        const newTimeNode = await currencyFun.getTimeNode('setting_product_time_format', this);
        if (Object.keys(newTimeNode).length === 0) {
            newTimeNode.start_time = '00:00:00';
            newTimeNode.end_time = '00:00:00';
        }
        this.timeArray = [
            util.timeFormat(new Date(new Date().getTime() - 3600 * 1000 * 24 * 1), 'yyyy-MM-dd') + ' ' + newTimeNode.start_time,
            util.timeFormat(new Date(new Date()), 'yyyy-MM-dd') + ' ' + newTimeNode.end_time,
        ];
    },
    mounted() {
        // 获取全部场站
        this.getStationData();
    },
    methods: {
        // 选择场站触发的事件
        stationChange() {
            this.lineData = [];
            this.formInline.line_code = '-';
            this.gitlineData();
            this.getTableData();
        },
        // 选择生产机组触发的事件
        lineChange() {
            this.getTableData();
        },
        // 获取场站list
        getStationData() {
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=' + this.$takeTokenParameters('Uid'))
                .then(res => {
                    if (res && res.length > 0) {
                        this.stationData = res;
                        if (this.stationData.length > 0) {
                            this.formInline.station_id = this.stationData[0].station_id;
                            this.gitlineData();
                        }
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产机组list
        gitlineData() {
            this.$axios
                .get(`/interfaceApi/production/mixstationline/${this.formInline.station_id}`)
                .then(res => {
                    this.lineData = res;
                    this.getTableData();
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 时间选择触发的事件
        timeChange() {
            this.getTableData();
        },
        async getTableData() {
            if (this.timeArray.length > 0) {
                this.formInline.start_time = this.timeArray[0];
                this.formInline.end_time = this.timeArray[1];
            }
            if (this.formInline.station_id) {
                this.loading = true;
                this.$axios
                    .get('/interfaceApi/production/productconsume/day/consume/' + this.formInline.station_id + '/'
                + this.formInline.line_code + '/' + this.formInline.start_time + '/' + this.formInline.end_time)
                    .then(res => {
                        this.loading = false;
                        this.tableData = res;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message({
                    message: '请先选择场站！',
                    type: 'warning',
                });
            }
        },
        exportExcel() {
            /* 从表生成工作簿对象 */
            const wb = XLSX.utils.table_to_book(document.querySelector('#out-table'));
            /* 获取二进制字符串作为输出 */
            const wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'array',
            });
            try {
                FileSaver.saveAs(
                // Blob 对象表示一个不可变、原始数据的类文件对象。
                // Blob 表示的不一定是JavaScript原生格式的数据。
                // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                    new Blob([wbout], { type: 'application/octet-stream' }),
                    // 设置导出文件名称
                    '原材料消耗日报表.xlsx'
                );
            } catch (e) {
                if (typeof console !== 'undefined') {console.log(e, wbout);}
            }
            return wbout;
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if (index === 1 || index === 2 || index === 3) {
                    sums[index] = '';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                    sums[index] = Number(sums[index].toFixed(2));
                    sums[index] += ' 吨';
                } else {
                    sums[index] = '';
                }
            });
            return sums;

        },
    },
};
</script>

<style lang="stylus" scope>
    .materialConsumeDaily
        .consume-box
            width 100%
            height 100%
            background #ffffff
            display flex
            flex-direction column
            header
                height 0.5rem
                padding 0.1rem 0.1rem 0 0.1rem
                ul
                    display flex
                    height 100%
                    li
                        margin-left 0.1rem
                        .el-date-editor
                            width 4rem !important
                            height 0.36rem
                        .el-input-group__append
                            background #1577d2
                            width 0.6rem
                            border none
                            padding 0
                            cursor pointer
                            .search-btn
                                border none
                                background #1577d2
                                width 0.6rem
                                cursor pointer
                                i
                                    color #ffffff
            section
                flex 1
                padding 0.1rem
                overflow hidden
                .table-wrapper
                    height 100%
                    .el-table
                        display flex
                        flex-direction column
                        .el-table__header-wrapper
                            th
                                text-align center
                                background-color #edf0f5
                        .el-table__body-wrapper
                            flex 1
                            .el-table__body
                                td
                                    text-align center
                        .el-table__footer-wrapper
                            td
                                padding 0.06rem 0
                                text-align center
            .page-wrapper
                text-align right
                margin-top 0.1rem
                padding-bottom 0.1rem


</style>
